import createHttp from '@dmm/create-http';
import { getConfig } from '../config/portal';
import get from 'lodash/get';
import * as utils from '../store/utils';
import { getCurrentLocale } from './language';
import { getBoatLoanConstants } from '../constants/BoatLoans';

const MAX_HTTP_RETRY = 4;
const HEADER_PASSTHRU_LIST = ['x-datadome-isbot', 'x-forwarded-for', 'x-type-portal'];

//Passthru some of the request headers downstream
const _headerPassthru = (http, headers = {}) => {
  Object.keys(headers)
    .filter((name) => HEADER_PASSTHRU_LIST.includes(name))
    .forEach((name) => {
      http.setHeader(name, headers[name]);
    });
};

const _configureHttp = (http, headers, retry) => {
  _headerPassthru(http, headers);
  http.defaults.retry = retry;
  return http;
};

// Direct API Client
export const getDirectApiClient = (headers) =>
  _configureHttp(
    createHttp({
      baseURL: get(getConfig(), 'server.api.endpoint'),
      endpointPrefix: get(getConfig(), 'server.api.prefix'),
      retryFor: [500, 502, 503, 504, 599]
    }),
    headers,
    2
  );

export const getApiClient = (headers) => getDirectApiClient(headers);

export const getReactClient = (headers = {}) => {
  let baseURL = get(getConfig(), 'client.apiGateway.endpoint');
  const supportsSameDomainClientApiGatewayEndpoint = get(
    getConfig(),
    'supports.sameDomainClientApiGatewayEndpoint',
    false
  );

  if (supportsSameDomainClientApiGatewayEndpoint) {
    baseURL = _getBaseUrl(baseURL);
  }

  return _configureHttp(
    createHttp({
      baseURL: baseURL,
      endpointPrefix: get(getConfig(), 'client.apiGateway.prefix'),
      retryFor: [500, 502, 503, 504, 599],
      returnRowError: true
    }),
    headers,
    MAX_HTTP_RETRY
  );
};

export const getHttpClient = (headers) => {
  return utils.isServer() ? getApiClient(headers) : getReactClient(headers);
};

export const tridentLeadHttpClient = (headers) =>{
  const boatLoansConstants = getBoatLoanConstants(getConfig());
  const { LEADS_TRIDENT_API_URL } = boatLoansConstants;
  return _configureHttp(
    createHttp({
      baseURL: LEADS_TRIDENT_API_URL
    }),
    headers,
    MAX_HTTP_RETRY
  );
};
const _getBaseUrl = (baseURL) => {
  const selectedLocale = getCurrentLocale(true);
  /*
  Configured for YW support only.
  We check for en-US because the hardcoded apigateway endpoint in portal config supports it.
  */
  const isLocaleUS = selectedLocale === 'en-US';

  if (typeof window !== 'undefined' && !isLocaleUS) {
    const origin = window?.location?.origin;

    if (origin?.includes('localhost') || origin?.includes('dev.')) {
      return baseURL;
    }

    // Remove port from origin if it exists
    return origin.replace(/:\d+$/, '');
  }

  return baseURL;
};
